export default {
    /**
     * Set the current page locale.
     *
     * @param {object} state
     * @param {string} locale
     */
    setLocale(state, locale) {
        state.locale = locale;
    },

    /**
     * Set the available page locales.
     *
     * @param {object} state
     * @param {string[]} locales
     */
    setLocales(state, locales) {
        state.locales = locales;
    },
};
